import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Model`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=app/Models/Post.php",
        "path": "app/Models/Post.php"
      }}>{`namespace App\\Models;

use Illuminate\\Database\\Eloquent\\Model;

class Post extends Model
{
    protected $fillable = [
        'title',
        'content'
    ];
}
`}</code></pre>
    <h2>{`Route`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=routes/web.php",
        "path": "routes/web.php"
      }}>{`use App\\Http\\Controllers\\PostController;

Route::resource('posts', [PostController::class, 'index']);
`}</code></pre>
    <h2>{`Controller`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=app/Http/Controllers/PostController.php",
        "path": "app/Http/Controllers/PostController.php"
      }}>{`<?php

namespace App\\Http\\Controllers;

use Illuminate\\Http\\Request;
use App\\Models\\Post;

class PostController extends Controller
{
    public function index()
    {
        $posts = Post::all();

        return view('posts.index', ['posts' => $posts]);
    }

    public function show(Post $post)
    {
        return view('posts.show', ['post' => $post]);
    }

    public function create()
    {
        return view('posts.create');
    }

    public function store(Request $request): RedirectResponse
    {
        $post = new Post();

        $post->fill([
            'title'   => $request->input('title'),
            'content' => $request->input('content'),
        ]);

        $post->save();

        return redirect()->route('posts.index');
    }
}
`}</code></pre>
    <h2>{`View`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "path=resources/views/posts/show.blade.php",
        "path": "resources/views/posts/show.blade.php"
      }}>{`<!DOCTYPE html>
<html>
    <head>
        <title>{{ $post->title }}</title>
    </head>
    <body>
        @include('template.partials.header')

        <div class="content">
            @unless(empty($post->content))
                <p>{{ $post->content }}</p>
            @else
                <p>No content available.</p>
            @endunless
        </div>

        @if($showDebugInfo)
            <pre>
                {{ json_encode($post->toArray(), JSON_PRETTY_PRINT) }}
            </pre>
        @endif
    </body>
</html>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      